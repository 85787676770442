import React from 'react';
import { Navbar, } from 'react-bootstrap';
// import { AiOutlineMail } from "react-icons/ai";

import './_header.scss';

const Header = (props) => {

  return (
      <div>
          <Navbar  expand="lg" className={'port-navbar port-default'}>
              <Navbar.Brand className={'port-navbar-brand'} href="/">Kraig Hartley</Navbar.Brand>

              {/*<Navbar.Toggle aria-controls="basic-navbar-nav"/>*/}
              {/*<Nav.Item className={'port-navbar-item'}>*/}
              {/*      <p ><AiOutlineMail size={'30px'}/> kraig.hartley@gmail.com</p>*/}
              {/*  </Nav.Item>*/}
              {/*<Navbar.Collapse id="basic-navbar-nav">*/}
              {/*    /!*Used ml-auto to get them to the left of the nav bar*!/*/}
              {/*    <Nav className="ml-auto">*/}
              {/*        /!*<Nav.Link className={'port-navbar-link'} href="/">Home</Nav.Link>*!/*/}
              {/*        /!*<Nav.Link className={'port-navbar-link'} href="/about">About</Nav.Link>*!/*/}
              {/*        /!*<Nav.Link className={'port-navbar-link'} href="/education">Education</Nav.Link>*!/*/}
              {/*        /!*<Nav.Link className={'port-navbar-link'} href="/portfolio">Portfolio</Nav.Link>*!/*/}

              {/*    </Nav>*/}
              {/*</Navbar.Collapse>*/}
          </Navbar>
      </div>
  );
}

export default Header;