import React, {Component} from 'react';
import {Row, Col, } from 'react-bootstrap';
import './pages.css';
import './portfolio.css';
import BasePage from "./BasePage";
import PortfolioCard from "../PortfolioCard/PortfolioCard";
import { portfolios } from "./Projects";


class Portfolio extends Component {

    renderPortfolios = (portfolios) => {
        return portfolios.map((portfolio, index) => {
            return (
                <Col key={index} md={'4'} className={'portfolio-col'}>
                    <PortfolioCard portfolio={portfolio}>
                    </PortfolioCard>
                </Col>
            )
        })
    }

    render() {
        return (
            <BasePage className={'portfolio-page'} title={'Portfolio'}>

                <Row>
                    {this.renderPortfolios(portfolios)}
                </Row>
            </BasePage>
        );
    }
}

export default Portfolio;