import React, {Component} from 'react';
import {Container, Col, Row, Image} from 'react-bootstrap';
import './pages.css';
import BasePage from "./BasePage";
import JHU from '../../assets/images/univ/jhu_horz_resized.png';
import UM from '../../assets/images/univ/umd-logo.png';
import './Education.css'

class Education extends Component {
    render() {
        return (
            <BasePage title={'Education'}>
                <div className={'page'}>
                    <Container algin-content={'center'}>
                        <Row className={'mt-20 mb-20'}>
                            <Col className={'d-none d-xl-block'}>
                                <Image src={JHU} alt={'No Image'} className={'edu-image'}/>
                            </Col>
                            <Col xs={8} >
                                <p className={'jhu-title'}>Johns Hopkins University, Baltimore, MD</p>
                                <p className={'edu-text'}>Master of Mechanical Engineering in Robotics and Controls</p>
                            </Col>
                        </Row>
                        <Row>
                            <hr/>
                        </Row>
                        <Row className={'mt-20 mb-20'}>
                            <Col className={'d-none d-xl-block'}>
                                <Image src={UM} alt={'No Image'} className={'edu-image'}/>
                            </Col>
                            <Col xs={8}>
                                <p className={'umd-title'}>University of Maryland, College Park, MD</p>
                                <p className={'edu-text'}>B.S. in Mechanical Engineering</p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </BasePage>
        );
    }
}

export default Education;