import React, {Component} from 'react';
import {Modal, Button, Image} from "react-bootstrap";
import {GoLogoGithub, GoMarkGithub} from "react-icons/go";

import './PortfolioModal.css';

class PortfolioModal extends Component {

    render() {
        const {show, onHide, portfolio} = this.props;
        return (
            <div>
                <Modal show={show} onHide={onHide} centered size={'lg'} >
                    <Modal.Header className={'modal-title'}>{portfolio.title}</Modal.Header>
                    <Modal.Body >
                        <Image src={portfolio.image} className={'modal-image mx-auto d-block'} fluid/>
                        <p className={'modal-text'}>{portfolio.description}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <a href={portfolio.git} onMouseOver={{color: 'red'}}>
                            <GoMarkGithub size={'50px'} />
                            <GoLogoGithub size="75px"/>
                        </a>
                        {/*<GoLogoGithub size="75px" color="red"/>*/}
                        <Button variant="secondary" onClick={onHide}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default PortfolioModal;