import flutter_store from '../../assets/images/projects/flutter_bookstore.jpg';
import django_store from '../../assets/images/projects/DJ_home_page.PNG';
import mnist_2 from '../../assets/images/projects/mnist_2.jpg';
import cartpole from '../../assets/images/projects/cartpole.jpg';
import img_front from '../../assets/images/projects/img_classifier_dark.PNG';
import iris from '../../assets/images/projects/iris_data_set.png';
import dscratch from '../../assets/images/projects/ds_from_scratch.png';
import ttt_qtable from '../../assets/images/projects/ttt_q_table_only.png';

export const portfolios = [
    {
        title: 'Django Bookstore',
        cardtype: 'Django',
        variant: 'Primary',
        description: 'An e-comm Bookstore selling Django books',
        color: 'header-python',
        image: django_store,
        git: 'https://github.com/kupchoy/Django-Bookstore',
    },
    {
        title: 'React Image Classifier',
        cardtype: 'React',
        variant: 'Primary',
        description: 'Drag and drop image classifier',
        color: 'header-react',
        image: img_front,
        git: 'https://github.com/kupchoy/React-Image-Classifier',
    },
    {
        title: 'Data Science from Scratch',
        cardtype: 'Machine Learning',
        variant: 'Primary',
        description: 'Code from working through the book',
        color: 'header-machine',
        image: dscratch,
        git: 'https://github.com/kupchoy/DS-from-Scratch',
    },
    {
        title: 'Flutter Django Bookstore',
        cardtype: 'Flutter',
        variant: 'Primary',
        description: 'An e-comm Bookstore selling Django books.  Accesses an API from the Django store',
        color: 'header-flutter',
        image: flutter_store,
        git: 'https://github.com/kupchoy/Flutter-Django-Bookstore',
    },
    {
        title: 'Iris Dataset',
        cardtype: 'Machine Learning',
        variant: 'Primary',
        description: 'Classify the Iris dataset using a neural network and an AdaBoost classifier',
        color: 'header-machine',
        image: iris,
        git: 'https://github.com/kupchoy/Iris-dataset-classification',
    },
    {
        title: 'Flutter MNIST',
        cardtype: 'Flutter',
        variant: 'Primary',
        description: 'A Flutter app that recognizes handwritten numbers.  Uses a tensorflow lite model.',
        color: 'header-flutter',
        image: mnist_2,
        git: 'https://github.com/kupchoy/Flutter-mnist-app',
    },
    {
        title: 'Cartpole DQN',
        cardtype: 'Q Learning',
        variant: 'Primary',
        description: 'A Deep Q Learning model that solves the cartpole problem',
        color: 'header-machine',
        image: cartpole,
        git: 'https://github.com/kupchoy/cartpole_dqn',
    },
    {
        title: 'Q Table TicTacToe',
        cardtype: 'Q Learning',
        variant: 'Primary',
        description: 'Python script to train a tic tac toe agent',
        color: 'header-machine',
        image: ttt_qtable,
        git: 'https://github.com/kupchoy/Q-Table-Tic-Tac-Toe',
    }
]

export default {
    portfolios,
}

