import React from 'react';
import './App.css';
import Education from './components/Pages/Education';
// import About from './components/Pages/About';
import Portfolio from "./components/Pages/Portfolio";
import MainLayout from "./components/MainLayout";
import 'bootstrap/dist/css/bootstrap.min.css';
// import Header from './components/Navigation/Header/Header';
import Layout from "./hoc/Layout";
// import {Route, BrowserRouter} from 'react-router-dom';
import Footer from "./components/Navigation/Footer/Footer";
import Contact from "./components/Pages/Contact"

class App extends React.Component {
    render() {
        return (

                <div className="App">
                    <Layout>
                        <MainLayout/>
                        <Education/>
                        <Portfolio/>
                        <Contact/>
                        <Footer/>
                    </Layout>
                </div>

        );
    }
}

export default App;
