import React from 'react';
import {Navbar, Nav, Image,} from 'react-bootstrap';
// import {GoLogoGithub, GoMarkGithub} from "react-icons/go";
import {FaRegCopyright} from "react-icons/fa";
import Flag from '../../../assets/images/Flag_of_Ohio.svg';

import './Footer.css';

const Footer = () => {
    return (
        <div className={'footer'}>
            <Navbar expand={'lg'}  className={'justify-content-center page-footer'}>
                <Nav.Item className={'footer-copyright justify-content-center'}>
                    <p className={'justify-content-center'}><FaRegCopyright/>  {new Date().getFullYear()}  Made in  </p>
                </Nav.Item>
                <Nav.Item>
                    <Image src={Flag} width={'75'} height={'30'} className={'d-inline-block align-top'}/>
                </Nav.Item>
            </Navbar>
        </div>
    );
};

export default Footer;