import React, {Component, } from 'react';
import {Card} from "react-bootstrap";
import PortfolioModal from "./PortfolioModal";
import '../Pages/portfolio.css';

class PortfolioCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false
        };

        this.handleToggle = this.handleToggle.bind(this);
    }

    handleToggle() {
        this.setState({
            show: !this.state.show
        });
    }

    render() {
        const {portfolio, children} = this.props;
        const {show} = this.state;
        var headerClassname = ['portfolio-card-header', portfolio.color].join(' ');


        return (
            <span onClick={this.handleToggle}>
                <PortfolioModal onHide={this.handleToggle} show={show} portfolio={portfolio}/>

                <Card className={'portfolio-card h-100'}>
                    <Card.Header className={headerClassname}>{portfolio.cardtype}</Card.Header>
                    <Card.Body className={'portfolio-card-body'}>
                        <Card.Title className={'portfolio-card-title'}>{portfolio.title}</Card.Title>

                        <Card.Text className={'portfolio-card-text'}>
                            {portfolio.description}
                        </Card.Text>
                        <div>
                            {children}
                        </div>
                    </Card.Body>

                </Card>
            </span>
        );
    }
}

export default PortfolioCard;