import React, {Component} from 'react';
import axios from "axios";
import './pages.css';
import BasePage from "./BasePage";

class Contact extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            subject: '',
            email: '',
            message: ''
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.state.name === '') {
            console.log(this.state.name.length);
            axios({
                method: "POST",
                url: "https://k36r60vrke.execute-api.us-east-1.amazonaws.com/test/contact",
                data: this.state
            }).then(this.resetForm())
        } else {
            console.log('message not sent');
        }
        // (response)=>{
        //     console.log(response.data)
        //   if (response.data.body === 'Email sent') {
        //     alert("Message Sent.");
        //     this.resetForm()
        //   } else if (response.data.status === 'fail') {
        //     alert("Message failed to send.")
        //   }
        // }

    }

    resetForm() {
        this.setState({name: '', subject: '', email: '', message: ''})
    }

    render() {
        return (
            <BasePage title={'Contact'}>
                <div>
                    <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Email address</label>
                            <input type="email" className="form-control" id="email" aria-describedby="emailHelp"
                                   autoComplete={'none'}
                                   value={this.state.email} onChange={this.onEmailChange.bind(this)}/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="subject">Subject</label>
                            <input type="text" className="form-control" id="subject" value={this.state.subject}
                                   autoComplete={'none'}
                                   onChange={this.onSubjectChange.bind(this)}/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">Message</label>
                            <textarea className="form-control" rows="5" id="message" value={this.state.message}
                                      autoComplete={'none'}
                                      onChange={this.onMessageChange.bind(this)}/>
                        </div>
                        <div className="form-group">
                            <input type="text" className="form-control" id="name" value={this.state.name}
                                   autoComplete={'none'}
                                   hidden={true}
                                   onChange={this.onNameChange.bind(this)}/>
                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                </div>
            </BasePage>
        );
    }

    onNameChange(event) {
        this.setState({name: event.target.value})
    }

    onSubjectChange(event) {
        this.setState({subject: event.target.value})
    }

    onEmailChange(event) {
        this.setState({email: event.target.value})
    }

    onMessageChange(event) {
        this.setState({message: event.target.value})
    }
}

export default Contact;