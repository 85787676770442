import React, {Component} from 'react';

import Aux from './auxiliary';
import Header from "../components/Navigation/Header/Header";
import Footer from "../components/Navigation/Footer/Footer";
import './Layout.css';

class Layout extends Component {
    render() {
        return (
            <Aux>
                <Header/>
                <main className={'Content'}>
                    {this.props.children}
                </main>
                {/*<Footer/>*/}
            </Aux>
        );
    }
}

export default Layout;