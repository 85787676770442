import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import Typed from 'react-typed';

import desk from '../assets/images/tech_desk_rasppi.jpg';
import './MainLayout.css';
import Aux from "../hoc/auxiliary";

import Education from "./Pages/Education";
import Portfolio from "./Pages/Portfolio";

class MainLayout extends React.Component {

    constructor(props) {
        super(props);
        this.roles = ['Structural Analyst', 'Machine Learning Engineer',
            'Full Stack Development', 'Python and Django', 'React with Redux', 'Flutter'];
    }

    render() {
        return (
            <Aux>
                <div className="cover">
                    <div className="main-section">
                        <Container>
                            <section id={'main'}>
                                <Row>
                                    <Col md="6">
                                        <div className="hero-section">
                                            <div className={`flipper`}>
                                                <div className="back">
                                                    <div className="hero-section-content">
                                                        <h2> Mechanical Engineer</h2>
                                                        <div className="hero-section-content-intro">
                                                            that's branching out.
                                                        </div>
                                                    </div>
                                                    <img className="image" src={desk} alt={''}/>
                                                    <div className="shadow-custom">
                                                        <div className="shadow-inner">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md="6" className="hero-welcome-wrapper">
                                        <div className="hero-welcome-text">
                                            <h1>
                                                Welcome to the portfolio website of Kraig Hartley.
                                                Get informed and discover projects that I have worked on!
                                            </h1>
                                        </div>

                                        <Typed
                                            loop
                                            typeSpeed={70}
                                            backSpeed={70}
                                            strings={this.roles}
                                            smartBackspace
                                            shuffle={false}
                                            backDelay={1000}
                                            loopCount={0}
                                            showCursor
                                            className={'self-typed'}
                                            cursorChar="|"
                                        />

                                        <div className="hero-welcome-bio">
                                            <h1>
                                                Let's take a look at my work.
                                            </h1>
                                        </div>
                                    </Col>
                                </Row>
                            </section>
                        </Container>
                    </div>
                </div>
            </Aux>

        );
    }
}

export default MainLayout;