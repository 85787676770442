import React from 'react';
import { Container } from 'react-bootstrap';
import './base-page.css'

const BasePage = (props) => {
    const { className, title, } = props;

        return (
            <div className={'base-page'}>
                <Container>
                    { title && <div className={'page-header'}><h1 className={'page-header-title'}>{title}</h1></div>}
                    {props.children}
                </Container>
            </div>
        );
    }

BasePage.propTypes = {};

export default BasePage;